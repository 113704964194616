.calcbg{
	background-image: url("../img/math3.jpg");
	background-size: cover;
	background-attachment: fixed;
}



.cal_body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
.wrapper2 {
  max-width: 275px;
  min-height: 40vh;
  margin: 0 auto;
}

.cal_button {
  font-size: 1rem;
  border: 1px solid #333;
  cursor: pointer;
}
.modifiers button {
  background-color: burlywood;
}
.operations button {
  background-color: lightsteelblue;
  width: 100px;
}
.digits button {
  background-color: #efefef;
}

/* All things Grid, Flexbox, Box Alignment */
.wrapper2,
.subgrid {
  display: grid;
}
.wrapper2 {
  grid-template-areas:
    "total total total total"
    "modif modif modif oper"
    "digit digit digit oper"
    "digit digit digit oper"
    "digit digit digit oper"
    "digit digit digit oper";
  grid-auto-columns: 1fr;
}
.show-input {
  grid-area: total;
}
.modifiers {
  grid-area: modif;
}
.operations {
  grid-area: oper;
}
.digits {
  grid-area: digit;
}

.show-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
}

.modifiers {
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.digits {
  display: flex;
  flex-wrap: wrap;
}
.digits button {
  flex: 1 0 26%;
}

