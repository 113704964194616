/* p,
li {
  line-height: 3.2rem;
  margin-bottom: 3.2rem;
} */
a {text-decoration:none;}
h2 {
  font: bold 2.4rem/4rem helvetica, arial, sans-serif;
  letter-spacing: .1rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}

article {
  position: relative;
  width: 90%;
  max-width: 100%;
  margin: 0 auto;
}
.photo {

    display: flex;
    justify-content: center;
    align-items: center;
    Height: 100vh;
    Width: 100vw; 
    max-width: 100%;




  }


ol,
ul,
li {
  margin: 0;
  padding: 0;
}

.toc { padding: 2.4rem; margin:2.4rem;}
.toc,
.toc ol > li:before,
.chapter {
  position: relative;
  z-index: 2; }

.toc ol {
  counter-reset: item;
  position: relative; }
  .toc ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: .8rem;
    width: 2.4rem; }
  .toc ol li li:before {
    content: counters(item, ".") " "; }

.toc li {
  counter-increment: item;
  display: table;
  font-weight: 400;
  margin-bottom: .8rem;
  margin-left: 0;
  -webkit-transition: .3s;
  transition: .3s;
  width: 100%; }
  .toc li li {
    font-weight: 300;
    margin-bottom: 0;
    margin-left: 0; }
  .toc li .toc-page:before {
    content: '';
    display: block;
    left: 0;
    margin-top: 1.8rem;
    position: absolute;
    right: 4rem; }
  .toc li > a {
    display: inline-block;
    width: 100%; }
  .toc li a:hover span {
    font-weight: 600; 
color:black;}
  .toc li a:hover .toc-page:before {
    border-bottom-width: 2px; }

.chapter {
  display: inline-block;
  font-size: 1.8rem;
  line-height: 3.2rem;
  padding-right: .8rem; }

.toc-page {
  float: right; }

/*=== Colors === */
.toc,
.toc ol > li:before,
.chapter {
  background-color: #f7f9fb; }

.toc li .toc-page:before {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.9); }