
.backg {
    background-image:url('../img/zen.jpg');
    background-size: cover;
    background-repeat:no-repeat;
    background-position:center; 
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
   -ms-content-zooming: none;
    position:absolute;
    width:100%;
    height:100%;
    overflow: scroll;
  
}

.social-part .fa{
    padding-right:20px;
}
ul li a{
    margin-right: 20px;
}


 .wrapper {
  border: 1px dashed #95a5a6;
  height: 56px;
  margin-top: 16px;
  border-radius: 4px;
  position: relative;
  font-size: 20px;
}

.wrapper p {
  line-height: 31px;
}


